/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css" ;
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; overflow-x: hidden }
.modal-backdrop{
    z-index: 0;
    opacity: 0 !important
}
@media only screen and (max-width:768px) {
    body{
       overflow: hidden
    }
 }